<template>
  <div class="details">
    <div class="content_top">
      <div class="images">
        <div class="map_image">
          <img
            :src="
              mapimg
                ? mapimg
                : baseUrl + 'third/third/ossGetFile/' + content.mainGraph
            "
            alt=""
          />
        </div>
        <div class="min_image">
          <img
            :src="baseUrl + 'third/third/ossGetFile/' + item"
            alt=""
            v-for="(item, index) in minimg"
            :key="index"
            @mouseover="mouseOver(item)"
            @mouseleave="mouseLeave"
          />
        </div>
      </div>
      <div class="content_righ">
        <div class="message">
          <div>
            {{ content.name }}
          </div>
          <div class="serveType">服务类别：{{ content.serviceType }}</div>
        </div>
        <div class="mony">
          <div class="mony_type">价格</div>
          <div class="mony_num">
            <span class="mony_icon" v-if="content.price">￥</span
            ><span
              >{{ content.price ? content.price : "面议" }}
              <span>{{ content.unit }}</span></span
            >
          </div>
        </div>
        <div class="information">
          <div>
            <div>联系方式:</div>
            <div class="information_item">
              <span>{{ content.concateUser }}</span>
              <span style="margin-left: 10px">{{ content.phone }}</span>
            </div>
          </div>
          <div>
            <div>商家地址:</div>
            <div class="information_item">
              {{ content.address }}
            </div>
          </div>
          <div>
            <div>服务商名称</div>
            <div class="information_item">{{ content.name }}</div>
          </div>
        </div>

        <div class="details_btn" v-if="content.companyId != companyId">
          <el-button
            icon="hy-icon-chakantieziguanzhu"
            @click="enshrine(true)"
            v-if="!content.companyIfCollection"
            >关注</el-button
          >
          <el-button
            icon="hy-icon-guanzhu"
            @click="enshrine(false)"
            v-if="content.companyIfCollection"
            >取消关注</el-button
          >
          <el-button
            type="primary"
            @click="immediately"
            v-if="
              content.serviceType != '物流服务' &&
              content.serviceType != '金融服务'
            "
            >立即下单</el-button
          >
        </div>
      </div>
    </div>
    <!--  -->
    <div class="serve_detailes">
      <div class="serve_con">
        <div class="serve_color"></div>
        <div class="serve_ti">服务详情</div>
      </div>
      <div class="serve_img" v-html="content.content">
        <!-- <img src="../../../assets/fuwu/6.png" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
import { serviceGooddetail, collection } from "@/api/public";
import * as types from "@/store/action-types";
import Vue from "vue";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      companyId: Vue.ls.get(types.enterpriseId),
      baseUrl: baseUrl,
      content: {},
      mapimg: "",
      minimg: [],
    };
  },
  mounted() {
    this.getdetails();
  },
  methods: {
    enshrine(val) {
      if (!this.beforhandle("serviceHall:collect")) return;
      let id = this.$route.query.serviceGoodId;
      let list = [];
      list.push(id);
      let config = {
        collection: val,
        serviceGoodIdList: list,
      };
      collection(config).then((res) => {
        if (res.code == 0) {
          this.getdetails();
          if (val) {
            if (
              this.content.serviceType == "金融服务" ||
              this.content.serviceType == "物流服务"
            ) {
              this.$confirm(
                "关注成功，是否立即下单（或稍后在我关注的商品下单）?",
                "",
                {
                  confirmButtonText: "立即前往",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  if (this.content.serviceType == "金融服务") {
                    this.$router.push({
                      path: "appliedQuota",
                      query: {
                        serviceGoodId: this.$route.query.serviceGoodId,
                      },
                    });
                  } else if (this.content.serviceType == "物流服务") {
                    this.$router.push({
                      path: "usmyPublished",
                    });
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            } else {
              this.$message({
                message: "关注成功",
                type: "success",
              });
            }
          } else {
            this.$message({
              message: "取消关注成功",
              type: "success",
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getdetails() {
      let id = this.$route.query.serviceGoodId;
      serviceGooddetail(id).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          console.log(list);
          if (list.unit != "") {
            list.unit = "/" + list.unit;
          }
          this.content = list;
          this.minimg = res.data.imgUrlList;
          //  this.mapimg = this.baseUrl + "third/third/ossGetFile/" + this.minimg[0];
          console.log(this.minimg);
        }
      });
    },
    immediately() {
      if (!this.beforhandle("serviceHall:order")) return;
      let id = this.$route.query.serviceGoodId;
      this.$router.push({
        path: "/immediately?serviceGoodId=" + id,
        title: "立即购买",
      });
    },
    mouseOver(item) {
      this.mapimg = this.baseUrl + "third/third/ossGetFile/" + item;
    },
    mouseLeave() {
      this.mapimg =
        this.baseUrl + "third/third/ossGetFile/" + this.content.mainGraph;
    },
  },
};
</script>

<style lang="scss" scoped>
.information > div > div:nth-of-type(1) {
  white-space: nowrap;
}
::v-deep .hy-icon-chakantieziguanzhu {
  margin-right: 10px;
}
::v-deep .hy-icon-guanzhu {
  margin-right: 10px;
}
.details {
  width: 65%;
   min-width: 1150px;
  //   height: 100vh;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 17px auto;
  padding: 25px;
  .content_top {
    display: flex;
    border-bottom: 2px solid #0296ff;
    padding-bottom: 13px;
    .images {
      .map_image {
        img {
          width: 362px;
          height: 258px;
        }
      }
      .min_image {
        // display: flex;
        // justify-content: space-between;
        margin-top: 11px;
        :nth-child(1) {
          margin-left: 0;
        }
        img {
          margin-left: 20px;
          width: 65px;
          height: 60px;
        }
      }
    }
    .content_righ {
      width: 100%;
      margin-left: 21px;
      .message {
        border-bottom: 2px solid #0296ff;
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: bold;
        .serveType {
          font-weight: normal;
          font-size: 14px;
          margin-top: 10px;
        }
      }
      .mony {
        display: flex;
        margin-top: 26px;
        border-bottom: 1px dotted #ccc;
        padding-bottom: 30px;
        .mony_type {
          background: url("../../../assets/images/tag.png") no-repeat;
          width: 85px;
          height: 24px;
          color: white;
          line-height: 24px;
          background-size: 85px 100%;
          text-align: center;
          margin-top: 10px;
        }
        .mony_num {
          margin-left: 7px;
          color: #d0111a;
          font-size: 34px;
          font-weight: bolder;
          .mony_icon {
            font-weight: normal;
            font-size: 30px;
          }
        }
      }
      .information {
        margin-top: 10px;
        line-height: 36px;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px dotted #ccc;
        > div {
          display: flex;
          .information_item {
            margin-left: 20px;
          }
        }
      }
      .details_btn {
        margin-top: 13px;
        text-align: right;
        button:nth-child(1) {
          color: #0296ff;
          border: 1px solid #0296ff;
        }
      }
    }
  }
  .serve_detailes {
    margin-top: 15px;
    .serve_con {
      display: flex;
      .serve_color {
        width: 4px;
        height: 19px;
        background: #00a0ff;
        border-radius: 2px;
      }
      .serve_ti {
        font-size: 18px;
        margin-left: 15px;
        font-weight: bolder;
      }
    }
  }
}
.serve_img ::v-deep img {
  // max-width: 1200px;
  width: 100%;
}
</style>
